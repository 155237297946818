<template>
	<div>
		<div class="banner">
			<div class="conteudo-interno">
				<div class="nova-comunicacao-container">
					<h1>Cadastro de Campanhas</h1>
					<div class="container-btns" v-if="perfil != 5 && perfil != null">
						<router-link class="btn btn-laranja" :to="{ name: 'AdicionarCampanha' }">Nova
							Campanha</router-link>
					</div>
					<form @submit.prevent class="formulario-container">
						<div class="formulario-linha flex">
							<div class="formulario-item medio">
								<label class="formulario-item-label">Autorizada</label>
								<multiselect label="descricao" track-by="descricao" v-model="form.idSegmentacao"
									:options="autorizadaSelect" :searchable="true" :allow-empty="false" placeholder=""
									select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" @select="
										(form.idsAutorizada = []), (assistenciaSelecionados = [])
										" />
							</div>

							<div class="formulario-item medio" v-if="form.idSegmentacao.id == 1">
								<label class="formulario-item-label">Assistência</label>
								<multiselect ref="assistencia" label="descricao" track-by="descricao"
									v-model="form.idsAutorizada" :options="assistencias" :searchable="true"
									:allow-empty="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
									selected-label="Selecionado" @input="assistenciaSelecionado()" />
							</div>

							<div class="formulario-item medio" v-if="form.idSegmentacao.id == 2">
								<label class="formulario-item-label">Franquia</label>
								<multiselect ref="franquia" label="descricao" track-by="descricao"
									v-model="form.idsAutorizada" :options="franquias" :searchable="true"
									:allow-empty="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
									selected-label="Selecionado" @input="assistenciaSelecionado()" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Cargo</label>
								<multiselect v-model="form.idCargo" label="descricao" track-by="id" :options="cargosFiltro"
									:searchable="false" :multiple="false" placeholder="" select-label="Selecionar"
									deselect-label=" " selected-label="Selecionado" :allow-empty="false" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Região</label>
								<multiselect ref="regiao" v-model="form.idsRegiao" label="descricao" track-by="id"
									:options="regiao" :searchable="false" :multiple="true" :allow-empty="true"
									placeholder="" select-label="Selecionar" deselect-label="Remover"
									selected-label="Selecionado" />
							</div>



							<div class="formulario-item medio">
								<label class="formulario-item-label">Ativo</label>
								<multiselect v-model="form.ativo" label="nome" track-by="nome" :options="ativo"
									:searchable="false" :multiple="false" placeholder="" select-label="Selecionar"
									deselect-label=" " :allow-empty="false" selected-label="Selecionado" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Título</label>
								<multiselect v-model="form.titulo" label="titulo" track-by="titulo" :options="titulo"
									:searchable="true" :multiple="false" placeholder="" select-label="Selecionar"
									deselect-label="Remover" selected-label="Selecionado" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Data inicio</label>
								<datepicker :language="ptBR" v-model="form.dataInicio" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Data final</label>
								<datepicker :language="ptBR" v-model="form.dataFim" />
							</div>
						</div>

						<div class="box-excluir-todos">
							<p class="btn-excluir" @click="
								(assistenciaSelecionados = []), (form.idsAutorizada = [])
								" v-if="assistenciaSelecionados.length > 0">
								Limpar seleção
							</p>
						</div>
						<div id="box-filtro-assistencia" v-if="assistenciaSelecionados.length > 0">
							<div v-if="assistenciaSelecionados.length > 0" class="tabela-informacoes">
								<div class="box-colunas-assistencia tabela-linha tabela-linha-informacoes quebra-linha">
									<div v-for="(assistencia, index) in assistenciaSelecionados" :key="index"
										class="coluna-nome-assistencia">
										<p>{{ assistencia.descricao }}</p>
										<button @click="
											assistenciaSelecionados.splice(
												assistenciaSelecionados.indexOf(assistencia),
												1
											)
											" class="btn-excluir-assistencia">
											X
										</button>
									</div>
								</div>
							</div>
						</div>

						<div class="container-btns">
							<button class="btn btn-cancelar" @click="limparFiltro">
								Limpar
							</button>
							<button class="btn btn-laranja" @click="listar()">Filtrar</button>
						</div>
					</form>
					<transition name="fade-left" mode="out-in">
						<div class="paginacao" v-if="!semDados">
							<button :disabled="paginaBusca == 1" @click="pagAnterior">
								Anterior
							</button>
							<p>{{ paginaBusca }}</p>
							<button :disabled="ultimoItem" @click="proxPagina">
								Próxima
							</button>
						</div>
					</transition>
					<lista-campanhas :dados="dadosTabela" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { listarCampanhasFiltro, tituloCampanhas } from "@/services/campanhas";
import { listarAssistencias, listarRegioes } from "@/services/autorizadas";
import { listarCargos } from "@/services/espelho";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import ListaCampanhas from "./ComponenteListaDeCampanhas.vue";

export default {
	name: "CadastroBannerTreinamentosListar",

	components: {
		Multiselect,
		Datepicker,
		ListaCampanhas
	},

	data() {
		return {
			titulo: [],
			semDados: true,
			paginaBusca: 1,
			ultimoItem: false,
			dadosTabela: [],
			assistenciaSelecionados: [],
			regiao: [],
			cargos: [],
			cargosFiltro: [],
			nome: [],
			ativo: [
				{ id: true, nome: "SIM" },
				{ id: false, nome: "NÃO" }
			],
			ptBR: ptBR,
			form: {
				titulo: "",
				dataInicio: "",
				dataFim: "",
				ativo: { id: true, nome: "SIM" },
				idSegmentacao: [],
				idsAutorizada: [],
				idsRegiao: [],
				idCargo: []
			},
			assistencias: [],
			franquias: [],
			autorizadaSelect: [
				{
					id: 1,
					descricao: "Assistência"
				},
				{
					id: 2,
					descricao: "Franquia"
				}
			],
			selectTodosAssistencia: [],
			selectTodosFranquia: [],
			perfil: null
		};
	},

	watch: {
		"form.idSegmentacao"() {
			let idAssistencias = [1, 4, 7, 8, 9, 10];
			let idFranquias = [5, 6];
			if (this.form.idSegmentacao.id == 1) return this.cargosFiltro = this.cargos.filter(item => idAssistencias.find(i => i == item.id));
			if (this.form.idSegmentacao.id == 2) return this.cargosFiltro = this.cargos.filter(item => idFranquias.find(i => i == item.id));
		}
	},

	created() {
		this.listar(1);
		this.listarAssistencia();
		this.listarRegioes();
		this.buscarCargos();
		this.listaTitulosCampanhas();
		this.perfil = localStorage.getItem('perfil');
	},
	methods: {
		listarAssistencia() {
			listarAssistencias(1).then(resp => {
				this.selectTodosAssistencia = resp.data.map(item => item.id);
				this.assistencias = resp.data;
				this.assistencias.unshift({ id: "assistencia", descricao: "Todos" });
			});
			listarAssistencias(2).then(resp => {
				this.selectTodosFranquia = resp.data.map(item => item.id);
				this.franquias = resp.data;
				this.franquias.unshift({ id: "franquia", descricao: "Todos" });
			});
		},
		listarRegioes() {
			listarRegioes().then(resp => {
				this.regiao = resp.data;
				this.regiao.unshift({ id: 0, descricao: "TODOS" });
			});
		},
		listaTitulosCampanhas() {
			tituloCampanhas().then(resp => {
				this.titulo = resp.data;
			});
		},

		buscarCargos() {
			listarCargos().then(resp => {
				this.cargos = resp.data;
				this.cargos.unshift({ id: 0, descricao: "Todos" });
				this.cargosFiltro = this.cargos;
			});
		},
		limparFiltro() {
			(this.form = {
				titulo: "",
				dataInicio: "",
				dataFim: "",
				ativo: [],
				idSegmentacao: [],
				idsAutorizada: [],
				idsRegiao: [],
				idCargo: []
			}),
				(this.assistenciaSelecionados = []);
			this.form.idsAutorizada = [];
			this.listar();
		},
		assistenciaSelecionado() {
			if (
				this.form.idsAutorizada.id == "assistencia" ||
				this.form.idsAutorizada.id == "franquia"
			) {
				this.assistenciaSelecionados = [];
				return;
			}

			if (this.form.idsAutorizada != null) {
				this.assistenciaSelecionados.push(this.form.idsAutorizada);
				this.form.idsAutorizada = this.assistenciaSelecionados;
			}
		},
		listar(pag) {
			const data = {
				titulo: this.form.titulo.titulo,
				dataInicio: this.form.dataInicio,
				dataFim: this.form.dataFim,
				ativo: this.form.ativo.id,
				idSegmentacao: this.form.idSegmentacao.id,
				idsAutorizada: [],
				idsRegiao: [],
				idCargo: this.form.idCargo.id,
				pagina: pag,
				qtdPorPagina: 10
			};

			if (this.form.idsRegiao.id && this.form.idsRegiao.id == "") {
				data.idsRegiao = [];
			} else {
				data.idsRegiao = this.form.idsRegiao.map(id => id.id);
			}

			if (this.form.idsAutorizada.id == "assistencia") {
				data.idsAutorizada = this.selectTodosAssistencia;
			}

			if (this.form.idsAutorizada.id == "franquia") {
				data.idsAutorizada = this.selectTodosFranquia;
			}

			listarCampanhasFiltro(data).then(resp => {
				this.dadosTabela = [];
				if (resp.data.lista.length == 0) {
					this.treinamentos = [];
					if (pag && pag > 1) {
						this.ultimoItem = true;
					} else {
						this.semDados = true;
					}
				} else {
					resp.data.lista.length < 10
						? (this.ultimoItem = true)
						: (this.ultimoItem = false);
					if (pag) {
						this.paginaBusca = pag;
					}
					this.semDados = false;
					this.dadosTabela = resp.data.lista;
				}
			});
		},
		pagAnterior() {
			const pag = --this.paginaBusca;
			this.listar(pag);
		},
		proxPagina() {
			const pag = ++this.paginaBusca;
			this.listar(pag);
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss">
#box-filtro-assistencia {
	width: 100%;

	.tabela-informacoes {
		height: auto;
		max-height: 100px;
	}

	.tabela-linha.tabela-linha-informacoes .coluna-nome-assistencia {
		width: 250px;
		padding: 0px 10px;
		margin-bottom: 4px;
	}

	.quebra-linha {
		flex-wrap: wrap;
	}

	.box-colunas-assistencia {
		width: 100%;
		display: flex;
	}
}

.box-excluir-todos {
	width: 100%;
	margin-left: 15px;
	display: flex;
}

.btn-excluir-assistencia,
.btn-excluir {
	margin: 10px 0;
	font-weight: 800;
	width: auto;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	background: #f28809;
	text-align: center;
	cursor: pointer;
	display: block;
}

.btn-excluir-assistencia {
	padding: 5px;
	border: none;
	width: 30px;
}

.banner {
	position: relative;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;

	.tabela-linha-btns {
		min-width: 50px;
		max-width: 50px;
	}

	.flex {
		display: flex;
		justify-content: flex-start;
	}

	.medio {
		width: 33.33% !important;
	}
}
</style>
