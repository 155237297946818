<template>
  <div>
    <div v-if="dados.length == 0" class="tabela-sem-dados" key="Vazio">
      <p>Sem dados</p>
    </div>

    <div v-else class="tabela-informacoes" key="Lista">
      <div class="tabela-linha tabela-linha-titulo">
        <div class="tabela-linha-id"><p>ID</p></div>
        <div class="tabela-linha-nome"><p>Título</p></div>
        <div class="tabela-linha-nome"><p>Subtítulo</p></div>
        <div class="tabela-linha-data-inicio"><p>Data Inicio</p></div>
        <div class="tabela-linha-data-final"><p>Data Fim</p></div>
        <div class="tabela-linha-data-final"><p>Ativo</p></div>
        <div class="tabela-linha-data-final"><p>Criado em</p></div>
        <div class="tabela-linha-btns"></div>
      </div>
      <div
        class="tabela-linha tabela-linha-informacoes"
        v-for="item in dados"
        :key="item.id"
      >
        <div class="tabela-linha-id">
          <p>{{ item.id }}</p>
        </div>
        <div class="tabela-linha-nome">
          <p>{{ item.titulo }}</p>
        </div>
        <div class="tabela-linha-nome">
          <p>{{ item.subtitulo }}</p>
        </div>
        <div class="tabela-linha-data-final">
          <p>{{ formatarData(item.dataInicio) }}</p>
        </div>
        <div class="tabela-linha-data-final">
          <p>{{ formatarData(item.dataFim) }}</p>
        </div>
        <div class="tabela-linha-data-final">
          <p>{{ item.ativo ? 'Sim' : 'Não' }}</p>
        </div>
        <div class="tabela-linha-data-final">
          <p>{{ formatarData(item.dataCadastro) }}</p>
        </div>
        <div class="tabela-linha-btns">
          <div class="tabela-linha-btns-editar" @click="editarBanner(item.id)">
            <img src="@/assets/images/icones/editar.svg" alt="Editar" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dados: Array,
  },
  data() {
    return {
      paginaBusca: 1,
    };
  },
  methods: {
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    pagAnterior() {
      const pag = --this.paginaBusca;
      this.listar(pag);
    },
    proxPagina() {
      const pag = ++this.paginaBusca;
      this.listar(pag);
    },
    editarBanner(id) {
			this.$router.push(`/cadastro-campanhas/editar/${id}`);
		}
  }
};
</script>


<style lang="scss" scoped>
</style>